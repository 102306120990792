import React, { FC, useState } from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import classNames from 'classnames';
import { motion, Variants } from 'framer-motion';

import { useScreenService } from 'hooks/useScreenService';

import { InfoSectionProps } from './models.d';

const InfoSection: FC<InfoSectionProps> = ({ heading, text, image, side = 'left' }) => {
  const [isHovered, setIsHovered] = useState<boolean>(false);
  const { isLgUp } = useScreenService();

  const imageSide = isLgUp ? side : 'left';

  const imageClassnames = classNames('info-section__image', {
    [`info-section__image--${imageSide}`]: side,
  });

  const infoSectionClassnames = classNames('info-section', {
    [`info-section--${imageSide}`]: side,
  });

  const startPosition = imageSide === 'left' ? '-10vw' : '10vw';

  const infoSectionVariants: Variants = {
    hidden: {
      x: startPosition,
      opacity: 0,
    },
    visible: {
      x: 0,
      opacity: 1,
      transition: {
        delay: 0.2,
        duration: 0.6,
      },
    },
    hover: {
      scale: 1.3,
      transformOrigin: 1,
    },
  };

  return (
    <motion.div
      className={infoSectionClassnames}
      variants={infoSectionVariants}
      initial="hidden"
      whileInView="visible"
      viewport={{ amount: isLgUp ? 0.5 : 0.1 }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <motion.div variants={infoSectionVariants} animate={{ scale: isHovered ? 1.05 : 1.01 }}>
        <GatsbyImage
          image={image.image.gatsbyImageData}
          alt={image.altText}
          className={imageClassnames}
        />
      </motion.div>

      <div className="info-section__content">
        {heading ? <h3 className="info-section__heading">{heading}</h3> : null}
        <p className="info-section__text">{text.text}</p>
      </div>
    </motion.div>
  );
};

export default InfoSection;
