import React, { FC } from 'react';
import classNames from 'classnames';
import { motion, Variants } from 'framer-motion';

import { IntroSectionProps } from './models.d';

const IntroSection: FC<IntroSectionProps> = ({ text, justify = 'left' }) => {
  const introClassnames = classNames('intro-section', { [`intro-section--${justify}`]: justify });

  const introVariants: Variants = {
    hidden: {
      opacity: 0,
    },
    visible: {
      opacity: 1,
      transition: {
        delay: 0.4,
        duration: 0.6,
      },
    },
  };

  return (
    <motion.div
      className={introClassnames}
      variants={introVariants}
      initial="hidden"
      animate="visible"
    >
      {text}
    </motion.div>
  );
};

export default IntroSection;
