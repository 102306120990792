import React from 'react';
import { graphql } from 'gatsby';

import Container from 'components/organisms/Container';
import Banner from 'components/molecules/Banner';
import InfoSection from 'components/molecules/InfoSection';
import { InfoSectionProps } from 'components/molecules/InfoSection/models.d';
import IntroSection from 'components/atoms/IntroSection';
import Seo from 'components/atoms/Seo';

const Program = ({
  data: {
    contentfulOurProgram: {
      banner: { bannerSlide },
      programIntro: { programIntro },
      programOutro: { programOutro },
      programSections,
    },
  },
}) => (
  <div className="program">
    <Seo title="Nasz program" />
    <Banner slides={bannerSlide} />
    <Container>
      <IntroSection text={programIntro} justify="center" />
      <div className="program__info-wrapper">
        {programSections.map((section: InfoSectionProps, i: number) => (
          <InfoSection key={section.text.text} {...section} side={i % 2 === 0 ? 'left' : 'right'} />
        ))}
      </div>
      <IntroSection text={programOutro} justify="center" />
    </Container>
  </div>
);

export const query = graphql`
  {
    contentfulOurProgram {
      banner {
        bannerSlide {
          title
          image {
            image {
              gatsbyImageData(formats: WEBP)
            }
            altText
          }
        }
      }
      programIntro {
        programIntro
      }
      programOutro {
        programOutro
      }
      programSections {
        text {
          text
        }
        image {
          image {
            gatsbyImageData(formats: WEBP)
          }
          altText
        }
      }
    }
  }
`;

export default Program;
